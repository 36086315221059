import {Container, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YoutubeIcon from '@mui/icons-material/YouTube';
import { Link } from 'react-router-dom';

const Footer = () => {

  return (
    <Container style={{marginTop: '10rem', marginBottom: '10rem'}}>
        <Stack spacing={3}>
            <Grid justifyContent='center' alignItems='center' container xs={12} >
                <Typography textAlign={'center'} sx={{fontSize: {xs: '1.5rem', md: '3.5rem'}}} color='white' variant='h3' fontWeight='600'>We make us in <span style={{color: '#1AABE3'}}>happy customers!</span></Typography>
                <Typography textAlign={'center'} sx={{mt: 2}} color='white' variant='body2'>We love our customers and we love getting feedback!</Typography>
            </Grid>
            <Grid justifyContent='center' alignItems='start' container xs={12} gap={4} sx={{flexDirection: {xs: 'row', lg: 'row'}}}>
                <Grid item xs={12} lg={3}>
                    <Typography sx={{width: '100%',textAlign: {xs: 'center', md: 'start'}}} color='white' variant='body2' fontWeight='600'>About Excyan</Typography>
                    <br />
                    <Typography sx={{}} color='white' variant='body2'>We are a team of specialists who love to challenge what we already know.</Typography>
                    <br />
                    <a href='https://www.linkedin.com/company/excyan' style={{textDecoration: 'none', color: 'white', }}><LinkedInIcon style={{fontSize: '2rem', marginLeft: '1rem'}} /></a>
                    <a href='https://www.youtube.com/channel/UCxky_sU-KPAW-fxZjjhNRng' style={{textDecoration: 'none', color: 'white', }}><YoutubeIcon style={{fontSize: '2rem', marginLeft: '1rem'}} /></a>
                    <br />
                    <Typography sx={{mt: '1rem'}} color='white' variant='body2' fontWeight='600'>Contact@Excyan.com</Typography>
                </Grid>
                <Grid item xs={12} lg={3} justifyContent='start' sx={{display: {xs: 'flex'}, flexDirection: {xs: 'row', md: 'column'}, gap: '1rem', flexWrap: 'wrap' }}>
                    <Typography sx={{width: '100%',textAlign: {xs: 'center', md: 'start'}}} color='white' variant='body2' fontWeight='600'>Resources</Typography>
                    <Link to='/' style={{textDecoration: 'none', }}><Typography color='white' variant='body2' fontWeight='400'>Home</Typography></Link>
                    <Link to='/Contact-Us' style={{textDecoration: 'none', }}><Typography color='white' variant='body2' fontWeight='400'>Contact Us</Typography></Link>
                    <Link to='/Blog' style={{textDecoration: 'none', }}><Typography color='white' variant='body2' fontWeight='400'>Blog</Typography></Link>
                    <Link to='/Case-Studies' style={{textDecoration: 'none', }}><Typography color='white' variant='body2' fontWeight='400'>Case Studies</Typography></Link>
                    <Link to='/About-Us' style={{textDecoration: 'none', }}><Typography color='white' variant='body2' fontWeight='400'>About Us</Typography></Link>
                </Grid>
                <Grid item xs={12} lg={3} justifyContent='start' sx={{display: {xs: 'flex'}, flexDirection: {xs: 'row', md: 'column'}, gap: '1rem', flexWrap: 'wrap' }}>
                    <Typography sx={{width: '100%',textAlign: {xs: 'center', md: 'start'} }} color='white' variant='body2' fontWeight='600'>Services</Typography>
                    <Link to='/Product-Engineering' style={{textDecoration: 'none', }}><Typography color='white' variant='body2' fontWeight='400'>Product Engineering</Typography></Link>
                    <Link to='/Cloud-and-DevOps-Engineering' style={{textDecoration: 'none', }}><Typography color='white' variant='body2' fontWeight='400'>Cloud & DevOps Engineering</Typography></Link>
                    <Link to='/Data-Engineering' style={{textDecoration: 'none', }}><Typography color='white' variant='body2' fontWeight='400'>Data Engineering</Typography></Link>
                    <Link to='/Data-Science' style={{textDecoration: 'none', }}><Typography color='white' variant='body2' fontWeight='400'>Data Science</Typography></Link>
                    <Link to='/Quality-Engineering' style={{textDecoration: 'none', }}><Typography color='white' variant='body2' fontWeight='400'>Quality Engineering</Typography></Link>
                </Grid>
            </Grid>
        </Stack>
    </Container>
  )
}

export default Footer