import React, { useCallback } from 'react'
import Particles from "react-particles";
import { loadFull } from "tsparticles";

const ParticlesG = () => {
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, [])
    
  return (
    <div>
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                "fullScreen": {
                "enable": true,
                "zIndex": -1
            },
            "particles": {
                "number": {
                    "value": 10,
                    "max": 20,
                    "density": {
                        "enable": true,
                        "area": 1000
                    }
                },
                "color": {
                    "value": "#ffffff"
                },
                "shape": {
                    "type": "character",

                    "character": {
                    "value": "$", // the text to use as particles, any string is valid, for escaping unicode char use the `\uXXXX` syntax
                    "font": "Verdana", // the font to use to draw the text. If the font needs an external css or javascript like FontAwesome you should include all the necessary files on your own
                    //"style": "", // any additional css style to add to the text
                    // "weight": "400" // the css weight property, some fonts like font awesome have a specified weight, check the documentation if needed
                    }
                },
                "opacity": {
                    "value": 1,
                    "random": false,
                    "anim": {
                        "enable": false,
                        "speed": 1,
                        "opacity_min": 0.1,
                        "sync": false
                    }
                },
                "size": {
                    "value": 30,
                    "random": true,
                    "anim": {
                        "enable": false,
                        "speed": 40,
                        "size_min": 0.1,
                        "sync": false
                    }
                },
                "move": {
                    "enable": true,
                    "speed": 1,
                    "direction": "none",
                    "random": false,
                    "straight": false,
                    "out_mode": "out",
                    "attract": {
                        "enable": false,
                        "rotateX": 600,
                        "rotateY": 1200
                    }
                }
            },
            "interactivity": {
                "events": {
                    "onHover": {
                        "enable": true,
                        "mode": "bubble",
                        "parallax": {
                            "enable": false,
                            "force": 60,
                            "smooth": 10
                        }
                    },
                    "onClick": {
                        "enable": true,
                        "mode": "push"
                    },
                    "resize": true
                },
                "modes": {
                    "grab": {
                        "distance": 400,
                        "lineLinked": {
                            "opacity": 1
                        }
                    },
                    "bubble": {
                        "distance": 400,
                        "size": 100,
                        "duration": 2,
                        "opacity": 1,
                        "speed": 3
                    },
                    "repulse": {
                        "distance": 200
                    },
                    "push": {
                        "particles_nb": 4
                    },
                    "remove": {
                        "particles_nb": 2
                    }
                }
            },
            "backgroundMask": {
                "enable": true,
                "cover": {
                    "value": {
                        "r": 29,
                        "g": 29,
                        "b": 29
                    }
                }
            },
            "retina_detect": true,
            "background": {
                "color": "#fff",
                "image": "url('https://particles.js.org/images/background3.jpg')",
                "position": "50% 50%",
                "repeat": "no-repeat",
                "size": "cover"
            }
            }}
        />
    </div>
  )
}

export default ParticlesG