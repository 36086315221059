import { Box, Button, CardActions, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';
import home1 from '../../Assets/home1.jpg'
import Particlesjs from './Particlesjs';
const Home = () => {

  return (
    <>       
        <Grid mt={5} pt={10} container xs={12} sx={{flexDirection: {lg: 'row', xs: 'column'}, p:{xs: "8rem 0 0 0"}}}>
            <Grid container xs={12} lg={7}>
                <Grid item xs={12} lg={12} sx={{ml: {xs: 0, lg: '15.5rem'}, p:{xs: 1, lg: 0} }}>
                    <Typography sx={{ fontSize: {xs: '1.5rem', md: '3rem'} }} variant='h2' fontWeight={600} color='white'>Excyan Software <span style={{color: '#1AABE3', backgroundColor: '#000'}}>Engineering</span> & <span style={{color: '#1AABE3', backgroundColor: '#000'}}>IT Solutions</span> company where you are the Priority</Typography>
                    <br />
                    <Typography variant='h6' sx={{fontSize: {xs: '1rem', md: '1.25rem'}}} color='white' style={{padding: '0 5px 0 10px', borderRadius: '5px'}}>Excyan is an ambitious and data-driven Engineering company that specialises in Software Engineering & IT Solutions creating online success and revenues for client companies.</Typography>
                </Grid>
            </Grid>
            <Grid container xs={12} lg={5} justifyContent='center' sx={{display: 'flex'}}>
                <Particlesjs />
            </Grid>
        </Grid>
        <Box mt={10}>
            <Grid container xs={12} justifyContent='center' alignItems='center' gap='5rem' sx={{flexDirection: {xs: 'column-reverse', lg: 'row'}}}>

                <Grid container lg={3} xs={10} justifyContent='center' alignItems='center'>
                    <img src={home1} style={{width: '100%', borderRadius: '5px'}} alt="" />
                </Grid>

                <Grid container lg={4} xs={12} spacing={2} justifyContent='center' alignItems='flex-start' >
                    <Grid item><Typography sx={{color: 'white', fontSize: {xs: '1.5rem'} }} variant='h4' fontWeight='600'>We are <span style={{color: '#1AABE3', backgroundColor: '#000'}}>Expert</span> agile product teams to<span style={{color: '#1AABE3', backgroundColor: '#000'}}> Scale your business</span></Typography></Grid>
                    <Grid item><Typography sx={{fontSize: {xs: '1rem', md: '1.25rem'}}} color='white' variant='h5'>We provide you with one stop ultimate software engineering & IT solutions ranging from Product Development to Cloud infrastructure. Experts which bring your ideas to life!</Typography></Grid>
                </Grid>

            </Grid>
            
            <Grid container justifyContent='center' alignItems='center' spacing={2} mt='5rem' xs={12}>
                <Grid item  xs={12} lg={12} justifyContent='center'><Typography color='white' textAlign='center' sx={{ fontSize: {xs: '1.5rem', md: '3rem'} }} variant='h3' fontWeight='600'>Your premium <span style={{color: '#1AABE3'}}>Digital Partner</span></Typography></Grid>
                <Grid item lg={6} xs={12}><Typography textAlign={'center'} sx={{fontSize: {xs: '1rem', md: '1.25rem'}}} color='white' variant='h6'>Our digital toolkit ranges from core development to optimization & Cloud computing - everything you need to ensure your business engineering and success.</Typography></Grid>
            </Grid>
            <Container>
                <Grid container xs={12} justifyContent='center'>
                    <Grid container justifyContent='start' sx={{mt: 5}} xs={11} sm={3.8} lg={3.8}>
                        <Box sx={{maxWidth: 345}} className="card-style">
                            <h5 className='text-white text-xl mb-2'>Product Engineering</h5>
                            <Typography variant="body2" >
                                Solve your unique business problems by building impactful software products.
                            </Typography>
                            <Link to='/Product-Engineering'>
                                <CardActions>
                                    <Button sx={{ fontSize: '0.6rem', color: 'white', border: '1px solid white', padding: '0.5rem', mt: '2rem'}} className='hover:border-black hover:text-black' size="small">Learn More</Button>
                                </CardActions>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid container justifyContent='start' sx={{mt: 5}} xs={11} sm={3.8} lg={3.8}>
                        <Box sx={{ maxWidth: 345 }} className="card-style">
                            <h5 className='text-white text-xl mb-2'>
                                Cloud Engineering
                            </h5>
                            <Typography variant="body2">
                                Gain business agility and flexibility by using scalability and data security of the cloud.
                            </Typography>
                            <Link to='/Cloud-and-DevOps-Engineering'>
                                <CardActions>
                                    <Button sx={{fontSize: '0.6rem', color: 'white', border: '1px solid white', padding: '0.5rem', mt: '2rem'}} className='hover:border-black hover:text-black' size="small">Learn More</Button>
                                </CardActions>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid container justifyContent='start' sx={{mt: 5}} xs={11} sm={3.8} lg={3.8}>
                        <Box sx={{ maxWidth: 345 }} className="card-style">
                            <h5 className='text-white text-xl mb-2'>
                                DevOps Engineering
                            </h5>
                            <Typography variant="body2">
                                Achieve faster development, fewer bugs and frequent delivery of products with our DevOps services.
                            </Typography>
                            <Link to='/Cloud-and-DevOps-Engineering'>
                                <CardActions>
                                    <Button sx={{fontSize: '0.6rem', color: 'white', border: '1px solid white', padding: '0.5rem', mt: '2rem'}} className='hover:border-black hover:text-black' size="small">Learn More</Button>
                                </CardActions>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid container justifyContent='start' sx={{mt: 5}} xs={11} sm={3.8} lg={3.8}>
                        <Box sx={{ maxWidth: 345 }} className="card-style">
                            <h5 className='text-white text-xl mb-2'>
                                Data Engineering
                            </h5>
                            <Typography variant="body2">
                                Create data pipeline to optimize your data management.
                            </Typography>
                            <Link to='/Data-Engineering'>
                                <CardActions>
                                    <Button sx={{fontSize: '0.6rem', color: 'white', border: '1px solid white', padding: '0.5rem', mt: '2rem'}} className='hover:border-black hover:text-black' size="small">Learn More</Button>
                                </CardActions>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid container justifyContent='start' sx={{mt: 5}} xs={11} sm={3.8} lg={3.8}>
                        <Box sx={{ maxWidth: 345 }} className="card-style">
                            <h5 className='text-white text-xl mb-2'>
                                Data Science
                            </h5>
                            <Typography variant="body2">
                                Take informed decisions using the insights generated by robust data systems.
                            </Typography>
                            <Link to='/Data-Engineering'>
                                <CardActions>
                                    <Button sx={{fontSize: '0.6rem', color: 'white', border: '1px solid white', padding: '0.5rem', mt: '2rem'}} className='hover:border-black hover:text-black' size="small">Learn More</Button>
                                </CardActions>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid container justifyContent='start' sx={{mt: 5}} xs={11} sm={3.8} lg={3.8}>
                        <Box sx={{ maxWidth: 345 }} className="card-style">
                            <h5 className='text-white text-xl mb-2'>
                                Quality Engineering
                            </h5>
                            <Typography variant="body2">
                                Develop solutions that perform optimally under extreme load conditions.
                            </Typography>
                            <Link to='/Quality-Engineering'>
                                <CardActions>
                                    <Button sx={{fontSize: '0.6rem', color: 'white', border: '1px solid white', padding: '0.5rem', mt: '2rem'}} className='hover:border-black hover:text-black' size="small">Learn More</Button>
                                </CardActions>
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Grid container justifyContent='center' alignItems='center' mt='5rem' xs={12}>
                <Grid item xs={12} lg={12} justifyContent='center'><Typography color='white' sx={{ fontSize: {xs: '1.5rem', md: '3rem'} }} textAlign='center' variant='h3' fontWeight='600'>Your Full Stack Solution's <span style={{color: '#1AABE3'}}>Outsourcing Partner</span></Typography></Grid>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' mt='2rem' xs={12}>
                <Container>
                    <Grid item sx={{color: 'white', display: 'flex', gap: '1.5rem'}} xs={12} lg={12} justifyContent='center' flexDirection='column'>
                        <Grid container sx={{flexDirection: {xs: 'row', sm: 'col'}}} xs={12} className="flex border-2 border-crimson-rgb h-36 w-full items-center rounded">
                            <Grid xs={12} sm={3} item className="flex w-2/6 items-center justify-center">
                                <Typography variant='h5' gutterBottom sx={{fontWeight: 'bold', pt: {xs: '1rem', md: '0'} }}>Agile Teams</Typography>
                            </Grid>
                            <Grid p={2} xs={12} sm={9} item>
                                <Typography sx={{fontSize: {xs: '0.85rem', md: '1.25rem', backgroundColor: 'black'}}} variant='h6'>Our Model is remote agile teams that have been built and refined with precision. You dont just get vetted engineers but also a transparent process. Our teams immerse themselves in your project, maintaining your company culture and working in line with your strategic goals</Typography>
                            </Grid>
                        </Grid>
                        <Grid container sx={{flexDirection: {xs: 'row', sm: 'col'}}} xs={12} className="flex border-2 border-crimson-rgb h-36 w-full items-center rounded">
                            <Grid  xs={12} sm={3} className="flex w-2/6 items-center justify-center">
                                <Typography variant='h5' textAlign='center' sx={{fontWeight: 'bold', pt: {xs: '1rem', md: '0'} }}>Core Development</Typography>
                            </Grid>
                            <Grid  xs={12} p={2} sm={9} className="flex w-full">
                                <Typography textAlign='center' sx={{fontSize: {xs: '0.85rem', md: '1.25rem'}, backgroundColor: 'black'}} variant='h6'>Excyan is built with purpose of complex core engineering work in a high growth environment. This gives us the edge over more traditional outsourcing models which dont support innovation and rapid change.</Typography>
                            </Grid>
                        </Grid>
                        <Grid container sx={{flexDirection: {xs: 'row', sm: 'col'}}} xs={12} className="flex border-2 border-crimson-rgb h-36 w-full items-center rounded">
                            <Grid xs={12} sm={3} className="flex w-2/6 items-center justify-center">
                                <Typography variant='h5' textAlign='center' sx={{fontWeight: 'bold', pt: {xs: '1rem', md: '0'} }}>Scale Without Limits</Typography>
                            </Grid>
                            <Grid xs={12} sm={9} p={2} className="flex w-full">
                                <Typography textAlign='center' sx={{fontSize: {xs: '0.85rem', md: '1.25rem'}, backgroundColor: 'black'}} variant='h6'>We prevent operational bottlenecks by utilizing out flexible global delivery mechanism and optimizing growth drivers. Through identification of critital business challenges with proven technological practices we align with your customer needs and organizational challenges.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>

        </Box>
    </>
  )
}

export default Home