import { Alert, Box, Container, Grid, Input, Stack, Typography } from '@mui/material'
import React, { useState, useRef } from 'react'
import pic1 from '../../Assets/home1.jpg'
import pic2 from '../../Assets/pic3.jpg'
import pic3 from '../../Assets/pic5.jpg'
import pic4 from '../../Assets/pic10.jpg'
import pic5 from '../../Assets/pic11.jpg'

import PropTypes from 'prop-types';
import emailjs from '@emailjs/browser';
import ParticlesW from './ParticlesW'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: {xs: 0, lg: '4rem 10rem' }}}>
            <Typography variant='h6' color='white'>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

const ProductEngineering = () => {
  const [success, setsuccess] = useState(false)

    if(success) {
        setTimeout(() => {
            setsuccess(false)
        }, 3000);
    }

    const [formData, setformData] = useState({Name:'',Email:'',PhoneNumber:'', Website:'', Description:''})

    const form = useRef();

    const handleSubmits = (e) => {
        e.preventDefault();
        console.log('x');
        emailjs.sendForm('service_p1co2fb', 'template_fqmixhf', form.current, '-yh_aCVLqtSwBXbKc')
        setformData({Name:'',Email:'',PhoneNumber:'', Website:'', Description:''})
    }

    const handleChanges = (e) => {
        const name = e.target.name
        const value = e.target.value
        setformData({...formData, [name]: value})
    }

  return (
    <>
        <ParticlesW />
        <Container>
            <Grid mt={10} pt={10} container xs={12} justifyContent='center' alignItems='center' gap='5rem' sx={{flexDirection: {lg: 'row', xs: 'column'}}}>

                <Grid container xs={12} lg={6} justifyContent='start' alignItems='flex-start'>

                    <Grid item><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1.5rem', md: '3rem'}}} variant='h3' fontWeight={600} color='white'>Transform and reach your true business potential with Excyan's new-age digital product <br /><span style={{color:'#1AABE3'}}>engineering services</span></Typography></Grid>
                    <br />
                    <Grid item><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'} }} variant='h6' color='white'>We assist you in innovating and making digital technology work for you perennially. Our team of digital experts assists you with human-centric design, development, testing, and consulting to help you transform everyday experiences.</Typography></Grid>

                </Grid>

                <Grid container xs={12} lg={5} justifyContent='center' alignItems='center'>
                    <img src={pic1} style={{width: '100%', borderRadius: '10px'}} alt="" />
                </Grid>

            </Grid>
        </Container>

        <Container style={{marginTop: '5rem'}}>
            <Grid container xs={12} justifyContent='center' alignItems='center' gap='5rem' sx={{flexDirection: {xs: 'column-reverse', lg: 'row'}}}>

                <Grid container justifyContent='center' alignItems='center' xs={12} lg={5}>
                     <img src={pic2} style={{width: '100%', borderRadius: '5px'}} alt="" />
                </Grid>

                <Grid container xs={12} lg={6} justifyContent='start' alignItems='flex-start'>
                    <Grid item><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)',fontSize: {xs: '1.5rem', md: '3rem'} }} color='white' variant='h4' fontWeight='600'>Why Excyan for <span style={{color: '#1AABE3'}}>digital engineering services?</span></Typography></Grid>
                    <br />
                    <Grid item><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'}}} color='white' variant='body1'>Excyan’s human-centric digital product engineering approach with the best industry expertise and experience support you create products that empower users, deliver business value and make a societal impact.</Typography></Grid>
                    <br />
                    <Grid item><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'}}} color='white' variant='body1'>Our team is proficient in conceptualizing, designing, prototyping, developing, and delivering new digital products. Our process starts with understanding end users’ requirements, followed by leveraging technology in a manner that meets those needs innovatively.</Typography></Grid>
                    <br />
                    <Grid item><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'}}} color='white' variant='body1'>Some of our uniquely designed services include product & platform strategy, product & platform engineering, product modernization, and product maintenance & support. Excyan has assisted leading software brands in winning the war on scalability, performance, and usability. When it comes to digital product engineering, Excyan helps you stay relevant.</Typography></Grid>
                </Grid>

            </Grid>
        </Container>

        <Grid container justifyContent='center' alignItems='center' mt='5rem' xs={12}>
            <Grid item xs={12} lg={12} justifyContent='center'><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1.5rem', md: '3rem'}}} color='white' textAlign='center' variant='h3' fontWeight='600'>Our Digital Product Engineering <span style={{color: '#1AABE3'}}>Services</span></Typography></Grid>
            <Grid item xs={12} lg={6}><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'}, mt: '1rem', textAlign: 'center' }} color='white' variant='h6'>The best software solutions are just one step away now with Excyan’s digital product engineering services! Explore our range of service offerings and select the best for your needs.</Typography></Grid>
        </Grid>

        <Container style={{marginTop: '5rem'}}>
            <Grid container xs={12} sx={{ justifyContent: {xs: 'center', lg: 'space-between'} }} gap='1rem'>
                <Grid container xs={12} lg={6} justifyContent='center' >
                    <Grid item xs={12} justifyContent='center'>
                        <Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1.5rem', md: '3rem'}}} color='white' variant='h4' fontWeight='600'>Website <span style={{color: '#1AABE3'}}>Application </span>Development</Typography>
                        <br />
                            <Typography color='white' sx={{ mt: 1, mb: 2, backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'} }} variant="h6" component="div">
                            Full Stack development using technologies of your choice. We also offer CMS developments and also support integration of cloud technologies including modern DevOps development integration.
                            </Typography>  
                    </Grid>
                </Grid>
                <Grid container xs={12} lg={5.5} justifyContent='center' >
                    <img src={pic3} style={{width: '100%', borderRadius: '5px'}} alt="" />
                </Grid>
            </Grid>
        </Container>

        <Container style={{marginTop: '5rem'}}>
            <Grid container xs={12}  justifyContent='space-between' alignItems='center' sx={{ justifyContent: {xs: 'center', lg: 'space-between'},flexDirection: {xs: 'column-reverse', lg: 'row'} }} gap='1rem'>

                <Grid container xs={12} lg={5.5} justifyContent='center'>
                  <img src={pic4} style={{width: '100%', borderRadius: '5px'}} alt="" />
                </Grid>

                <Grid container xs={12} lg={6} justifyContent='center' >
                    <Grid item xs={12} justifyContent='center'>
                        <Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1.5rem', md: '3rem'}}} color='white' variant='h4' fontWeight='600'>Mobile <span style={{color: '#1AABE3'}}>Application </span>Development</Typography>
                        <br />
                        <Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'} }} color='white' variant='h6'>Cutting Edge Industry standard development of Android and IOS Software Development using technologies of your choice. We also support integration of cloud technologies.</Typography>
                    </Grid>
                </Grid>
                
            </Grid>
        </Container>

        <Container style={{marginTop: '5rem'}}>
            <Grid container xs={12} sx={{ justifyContent: {xs: 'center', lg: 'space-between'} }} gap='1rem'>
                <Grid container xs={12} lg={6} justifyContent='center' >
                    <Grid item xs={12} justifyContent='center'>
                        <Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1.5rem', md: '3rem'}}} color='white' variant='h4' fontWeight='600'>UI / UX <span style={{color: '#1AABE3'}}>Design </span></Typography>
                        <br />
                            <Typography color='white' sx={{ mt: 0, mb: 2, backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'} }} variant="h6" component="div">
                            We design your dream application by using modern softwares of your choice such as figma,Adobe XD. From wireframes to design we provide excellent results.
                            </Typography>  
                    </Grid>
                </Grid>
                <Grid container xs={12} lg={5.5} justifyContent='center' >
                    <img src={pic5} style={{width: '100%', borderRadius: '5px'}} alt="" />
                </Grid>
            </Grid>
        </Container>

        <Container style={{marginTop: '5rem'}}>
            <Grid container xs={12} gap='5rem' sx={{ justifyContent: {xs: 'center', lg: 'space-between'}, alignItems: 'center', flexDirection: { xs: 'column', lg: 'row' } }}>
                <Grid container flex={1} gap='5rem'>
                    <form style={{width: '100%', display: 'flex', gap: '1rem', flexDirection: 'column'}} ref={form} onSubmit={handleSubmits}>
                        <Grid container xs={12} justifyContent='space-start' gap={'1rem'}>
                            <Grid item xs={12} sm={5.5}><Input required sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} type='text' name='Name' value={formData.Name} onChange={handleChanges} placeholder="Name" fullWidth/></Grid>
                            <Grid item xs={12} sm={5.5}><Input required sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} type='email' name='Email' value={formData.Email} onChange={handleChanges} placeholder="Email" fullWidth/></Grid>
                        </Grid>
                        <Grid container xs={12} justifyContent='space-start' gap='1rem'>
                            <Grid item xs={12} sm={5.5}><Input sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} type='text' name='PhoneNumber' value={formData.PhoneNumber} onChange={handleChanges} placeholder="Phone Number (optional)" fullWidth/></Grid>
                            <Grid item xs={12} sm={5.5}><Input sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} type='text' name='Website' value={formData.Website} onChange={handleChanges} placeholder="Website Domain" fullWidth/></Grid>
                        </Grid>
                        <Grid container xs={12} justifyContent='space-start' gap='1rem'>
                            <Grid item xs={12} sm={11.35}><Input required sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} type='text' name='Description' value={formData.Description} onChange={handleChanges} placeholder="Description Of Project" fullWidth/></Grid>
                        </Grid>
                        <Grid container xs={12} justifyContent='space-start' gap='1rem'>
                            {/* <input style={{background:'#26244C', padding: '1rem 3rem', border: 'none', borderRadius: '5px', color: 'white', cursor: 'pointer'}} type="submit" /> */}
                            <button  className='contact-button' type='submit' onClick={()=> setsuccess(true)}>Submit</button>
                        </Grid>
                        <div>
                            {
                                success 
                                ? 
                                    <Stack sx={{ width: '70%' }} spacing={2}>
                                        <Alert sx={{backgroundColor: '#333', color: 'white', fontSize: '1.1rem'}} severity="success">Your Message was successfully sent!</Alert>
                                    </Stack>
                                : 
                                    <></>
                            }
                        </div>
                    </form>
                </Grid>
                <Grid container flex={1} gap='2rem'>
                    <Grid item xs={12} sx={{ mb: 0}}><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)',fontSize: {xs: '1.5rem', md: '3rem'}}} color='white' variant='h4' fontWeight='600'>Should we <span style={{color: '#1AABE3'}}>work together?</span></Typography></Grid>
                    <Grid item xs={12} sx={{ mb: 5}}>
                        <Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'}}} color='white' variant='body1'>Do you want us to develop and design an exclusive web solution for your company?</Typography>
                        <br />
                        <Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'}}} color='white' variant='body1'>So don't hesitate to contact Excyan today to find out more about how we can make your dream of a beautiful and compelling web and mobile solution a reality.</Typography>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid container flex={1} flexDirection='column'>
                            <Grid item color='white' sx={{backgroundColor: 'rgba(29, 29, 29, 1)'}}>Email</Grid>
                            <Grid item color='white' sx={{backgroundColor: 'rgba(29, 29, 29, 1)'}}>Contact@excyan.com</Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </Grid>
        </Container>
    </>
  )
}

export default ProductEngineering