import { Alert, Container, Grid, Input, Stack, Typography } from '@mui/material'
import React, { useState, useRef } from 'react'
import emailjs from '@emailjs/browser';
import pic1 from '../../Assets/home1.jpg'
import ParticlesG from '../Software-Development/ParticlesG';
const ContactUs = () => {
    const [formData, setformData] = useState({Name:'',Email:'',PhoneNumber:'', Website:'', Description:''})
    const [success, setsuccess] = useState(false)

    if(success) {
        setTimeout(() => {
            setsuccess(false)
        }, 3000);
    }
    const form = useRef();

    const handleSubmits = (e) => {
        e.preventDefault();
        console.log('x');
        emailjs.sendForm('service_p1co2fb', 'template_fqmixhf', form.current, '-yh_aCVLqtSwBXbKc')
        setformData({Name:'',Email:'',PhoneNumber:'', Website:'', Description:''})
    }

    const handleChanges = (e) => {
        const name = e.target.name
        const value = e.target.value
        setformData({...formData, [name]: value})
    }
  return (
    <>
        <ParticlesG />
        <Container sx={{ marginTop: {xs: '7rem', md: '10rem'}, borderRadius: '5px'}}>
            <Grid container xs={12} sx={{ justifyContent: {xs: 'center', lg: 'space-between'}, alignItems: 'start', flexDirection: { xs: 'column', lg: 'row' } }}>
                <Grid container flex={1} gap='5rem'>
                <Grid item><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1.5rem', sm: '2rem'}}} color='white' variant='h4' fontWeight='600'>We are just a <span style={{color: '#1AABE3'}}>click away</span></Typography></Grid>
                    <form style={{width: '100%', display: 'flex', gap: '1rem', flexDirection: 'column'}} ref={form} onSubmit={handleSubmits}>
                        <Grid container xs={12} justifyContent='space-start' gap={'1rem'}>
                            <Grid item xs={12} sm={5.5}><Input required sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} type='text' name='Name' value={formData.Name} onChange={handleChanges} placeholder="Name" fullWidth/></Grid>
                            <Grid item xs={12} sm={5.5}><Input required sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} type='email' name='Email' value={formData.Email} onChange={handleChanges} placeholder="Email" fullWidth/></Grid>
                        </Grid>
                        <Grid container xs={12} justifyContent='space-start' gap='1rem'>
                            <Grid item xs={12} sm={5.5}><Input sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} type='text' name='PhoneNumber' value={formData.PhoneNumber} onChange={handleChanges} placeholder="Phone Number (optional)" fullWidth/></Grid>
                            <Grid item xs={12} sm={5.5}><Input sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} type='text' name='Website' value={formData.Website} onChange={handleChanges} placeholder="Website Domain" fullWidth/></Grid>
                        </Grid>
                        <Grid container xs={12} justifyContent='space-start' gap='1rem'>
                            <Grid item xs={12} sm={11.35}><Input required sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} type='text' name='Description' value={formData.Description} onChange={handleChanges} placeholder="Description Of Project" fullWidth/></Grid>
                        </Grid>
                        <Grid container xs={12} justifyContent='space-start' gap='1rem'>
                            {/* <input style={{background:'#26244C', padding: '1rem 3rem', border: 'none', borderRadius: '5px', color: 'white', cursor: 'pointer'}} type="submit" /> */}
                            <button  className='contact-button' type='submit' onClick={()=> setsuccess(true)}>Submit</button>
                        </Grid>
                        <div>
                            {
                                success 
                                ? 
                                    <Stack sx={{ width: '70%' }} spacing={2}>
                                        <Alert sx={{backgroundColor: '#333', color: 'white', fontSize: '1.1rem'}} severity="success">Your Message was successfully sent!</Alert>
                                    </Stack>
                                : 
                                    <></>
                            }
                        </div>
                    </form>
                </Grid>
                <Grid container flex={1} alignItems={'center'} justifyContent={'center'}>
                    <img src={pic1} style={{width: '100%', borderRadius: '5px', backgroundColor: 'rgba(29, 29, 29, 1)'}} alt="" />
                </Grid>
            </Grid>
        </Container>
    </>
  )
}

export default ContactUs