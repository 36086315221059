import { Grid, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Logo from '../../Assets/navbar/logo.png'
import './newnav.css'
const Navmobile = ({setnavOn, handleClick}) => {
  return (
    <Grid container justifyContent='start' flexDirection="column" alignItems='center' sx={{ backgroundColor: '#fff', color: 'black', minHeight: '100vh', zIndex: '99999'}}>
        <Grid container onClick={()=> {setnavOn(false)}}  justifyContent="space-between" className='' alignItems="center" sx={{width: '100%', height: '70px', overflow: 'hidden', background: '#1DB5CA'}}>
            <Grid item container justifyContent={'center'} alignItems={'center'} xs={6} height={'100px'}>
                    <Link to='/' style={{textDecoration: 'none', color: 'white', width: '100%',  display: 'flex',height: '100%', marginTop: '-1.75rem'}}><img src={Logo} className='logo-style' height='100%' alt="logo" /></Link>  
            </Grid>
            <Grid container item xs={6} justifyContent='flex-end' alignItems='center' height={'100%'}>
                <CloseIcon sx={{fontSize: "25px", borderRadius: "0.5rem", marginRight: '1rem', marginTop: '-1.75rem'}} onClick={handleClick} />
            </Grid>
        </Grid>
        <Grid container flexDirection="column" pl={2} mt={2} gap={1} justifyContent="start" alignItems="start" onClick={()=> {setnavOn(false)}} sx={{ minHeight: "75vh"}}>
            <Typography variant='h5' fontWeight={600} >Services</Typography>
            <Divider  component="div" sx={{color: 'black', width: '90%'}} />
            <Link to='/Product-Engineering' style={{textDecoration: 'none'}}><Typography variant='h6'>Product Engineering</Typography></Link>
            <Link to='/Cloud-and-DevOps-Engineering' style={{textDecoration: 'none'}}><Typography variant='h6'>Cloud & DevOps Engineering</Typography></Link>
            <Link to='/Data-Engineering' style={{textDecoration: 'none'}}><Typography variant='h6'>Data Engineering</Typography></Link>
            <Link to='/Data-Science' style={{textDecoration: 'none'}}><Typography variant='h6'>Data Science</Typography></Link>
            <Link to='/Quality-Engineering' style={{textDecoration: 'none'}}><Typography variant='h6'>Quality Engineering</Typography></Link>
            <Typography variant='h5' fontWeight={600} mt={2} >Company</Typography>
            <Divider  component="div" sx={{color: 'black', width: '90%'}} />
            <Link to='/Contact-Us'  style={{textDecoration: 'none'}}><Typography variant='h6'>Contact Us</Typography></Link>
            <Link to='/About-Us'  style={{textDecoration: 'none'}}><Typography variant='h6'>About Us</Typography></Link>
            <Link to='/Case-Studies'  style={{textDecoration: 'none'}}><Typography variant='h6'>Case Studies</Typography></Link>
            <Link to='/Blog'  style={{textDecoration: 'none'}}><Typography variant='h6'>Blog</Typography></Link>
            <Link to='/How-It-Works'  style={{textDecoration: 'none'}}><Typography variant='h6'>How it works</Typography></Link>
            <a href='https://www.vividmarketingagency.org' rel='noreferrer' target="_blank"  style={{textDecoration: 'none'}}><Typography variant='h6'>Digital Marketing</Typography></a>
        </Grid>
    </Grid>
  )
}

export default Navmobile