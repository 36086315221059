import { Alert, Input, Stack } from '@mui/material'
import React, { useState, useRef } from 'react'
import emailjs from '@emailjs/browser';
import { Button, Card, CardActions, CardContent, Container, Grid, Typography } from '@mui/material'

import ParticlesG from './ParticlesG';

const CaseStudies = () => {
    const [formData, setformData] = useState({Name:'',Email:'',PhoneNumber:'', Website:'', Description:''})
    const [success, setsuccess] = useState(false)

    if(success) {
        setTimeout(() => {
            setsuccess(false)
        }, 3000);
    }
    const form = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('x');
        emailjs.sendForm('service_p1co2fb', 'template_fqmixhf', form.current, '-yh_aCVLqtSwBXbKc')
        setformData({Name:'',Email:'',PhoneNumber:'', Website:'', Description:''})
    }

    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setformData({...formData, [name]: value})
    }
  return (
    <>
        <ParticlesG />
        <Grid mt={10} pt={10} container xs={12} sx={{flexDirection: {lg: 'row', xs: 'column'}}}>
            <Grid container xs={12} lg={7}>
                <Grid item xs={12} lg={8} sx={{ml: {xs: 0, lg: '15.5rem'}, p: "0rem 2rem 5rem 2rem" }}>
                    <Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '2rem', md: '3.5rem'}}} variant='h2' fontWeight={600} color='white'>Case <span style={{color:'#1AABE3'}}>Studies<br /></span></Typography>
                    <br />
                    <Typography variant='body 1' sx={{color: 'white'}}>This page is under construction</Typography>
                </Grid>
            </Grid>
        </Grid>

        <Container style={{}}>
                <Grid container xs={12} justifyContent='space-around'>
                    <Grid container justifyContent='start' sx={{mt: 5}} xs={8} sm={3.8} lg={3.8}>
                        <Card sx={{ maxWidth: 345 }}>

                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                Study 1
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                Solve your unique business problems by building impactful software products.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button sx={{color: 'black'}} size="small">Learn More</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid container justifyContent='start' sx={{mt: 5}} xs={8} sm={3.8} lg={3.8}>
                        <Card sx={{ maxWidth: 345 }}>

                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                Study 2
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Gain business agility and flexibility by using scalability and data security of the cloud.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button sx={{color: 'black'}} size="small">Learn More</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid container justifyContent='start' sx={{mt: 5}} xs={8} sm={3.8} lg={3.8}>
                        <Card sx={{ maxWidth: 345 }}>

                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                Study 3
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Achieve faster development, fewer bugs and frequent delivery of products with our DevOps services.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button sx={{color: 'black'}} size="small">Learn More</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid container justifyContent='start' sx={{mt: 5}} xs={8} sm={3.8} lg={3.8}>
                        <Card sx={{ maxWidth: 345 }}>

                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                Study 4
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Create data pipeline to optimize your data management.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button sx={{color: 'black'}} size="small">Learn More</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid container justifyContent='start' sx={{mt: 5}} xs={8} sm={3.8} lg={3.8}>
                        <Card sx={{ maxWidth: 345 }}>

                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                Study 5
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Take informed decisions using the insights generated by robust data systems.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button sx={{color: 'black'}} size="small">Learn More</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid container justifyContent='start' sx={{mt: 5}} xs={8} sm={3.8} lg={3.8}>
                        <Card sx={{ maxWidth: 345 }}>

                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                Study 6
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Develop solutions that perform optimally under extreme load conditions.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button sx={{color: 'black'}} size="small">Learn More</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>

            </Container>


        <Container style={{marginTop: '5rem'}}>
            <Grid container xs={12} gap='5rem' sx={{ justifyContent: {xs: 'center', lg: 'space-between'}, alignItems: 'start', flexDirection: { xs: 'column', lg: 'row' } }}>
                <Grid container flex={1} gap='5rem'>
                    <Grid item><Typography color='white' variant='h4' fontWeight='600'>Want to know more about your options?</Typography></Grid>
                    <form style={{width: '100%', display: 'flex', gap: '2rem', flexDirection: 'column'}} ref={form} onSubmit={handleSubmit}>
                        <Grid container xs={12} justifyContent='space-between' gap='1rem'>
                            <Grid item  flex={1}><Input sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} required className='inputfieldy' type='text' name='Name' value={formData.Name} onChange={handleChange} placeholder="Name" fullWidth/></Grid>
                            <Grid item  flex={1}><Input sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} required className='inputfieldy' type='email' name='Email' value={formData.Email} onChange={handleChange} placeholder="Email" fullWidth/></Grid>
                        </Grid>
                        <Grid container xs={12} justifyContent='space-between' gap='1rem'>
                            <Grid item flex={1}><Input sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} className='inputfieldy' type='text' name='PhoneNumber' value={formData.PhoneNumber} onChange={handleChange} placeholder="Phone Number (optional)" fullWidth/></Grid>
                            <Grid item flex={1}><Input sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} className='inputfieldy' type='text' name='Website' value={formData.Website} onChange={handleChange} placeholder="Website Domain" fullWidth/></Grid>
                        </Grid>
                        <Grid container xs={12} justifyContent='space-between' gap='1rem'>
                            <Grid item flex={1}><Input sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} required className='inputfieldy' type='text' name='Description' value={formData.Description} onChange={handleChange} placeholder="Description Of Project" fullWidth/></Grid>
                        </Grid>
                        <Grid container xs={12} justifyContent='space-between' gap='1rem'>
                            <button style={{backgroundColor: 'rgba(29, 29, 29, 1)'}} className='contact-button' type='submmit' onClick={()=> setsuccess(true)}>Submit</button>
                        </Grid>
                        <div>
                            {
                                success 
                                ? 
                                    <Stack sx={{ width: '70%' }} spacing={2}>
                                        <Alert sx={{backgroundColor: '#333', color: 'white', fontSize: '1.1rem'}} severity="success">Your Message was successfully sent!</Alert>
                                    </Stack>
                                : 
                                    <></>
                            }
                        </div>
                    </form>
                </Grid>
                <Grid container flex={1} gap='2rem'>
                    <Grid item xs={12}><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)'}} color='white' variant='h4' fontWeight='600'>Choose your own <span style={{color: '#1AABE3'}}>PATH</span></Typography></Grid>
                    <Grid item xs={12}><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)'}} color='white' variant='body1'>Unleash Your Potential</Typography></Grid>
                    <Grid item xs={12}><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)'}} color='white' variant='body1' fontWeight={600}>Let's overtake your competitors together</Typography></Grid>
                    <Grid container xs={12}>
                        <Grid container flex={1} flexDirection='column'>
                            <Grid item style={{color: 'white', backgroundColor: 'rgba(29, 29, 29, 1)'}} >Email</Grid>
                            <Grid item style={{color: 'white', backgroundColor: 'rgba(29, 29, 29, 1)'}} >Contact@excyan.coms</Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    </>
  )  
}

export default CaseStudies