import { Alert, Box, Button, Container, Grid, Input, Stack, styled, Typography } from '@mui/material'
import React, { useState, useRef } from 'react'
import pic1 from '../../Assets/pic1.jpg'
import pic2 from '../../Assets/pic2.jpg'
import pic7 from '../../Assets/pic11.jpg'

import PropTypes from 'prop-types';
import emailjs from '@emailjs/browser';
import ParticlesW from './ParticlesW'
import { Link } from 'react-router-dom'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: {xs: 0, lg: '4rem 10rem' }}}>
            <Typography variant='h6' color='white'>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  

const HowItWorks = () => {
  const [success, setsuccess] = useState(false)

    if(success) {
        setTimeout(() => {
            setsuccess(false)
        }, 3000);
    }

    const SubmitBtn = styled(Button)({
        color: '#08fdd8',
        background: "none",
        fontSize: "1.1rem",
        letterSpacing: "3px",
        fontFamily: "Open Sans, sans-serif",
        transition: "ease-out 0.6s",
        borderRadius: "1px",
        animation: "fadeInAnimation 1s 1.8s backwards",
        border: "1px solid #08fdd8",
        width: "225px",
        "&:hover": {
            boxShadow: "inset 260px 0 0 0 #08fdd8",
            cursor: "pointer",
            color: "black",
        },
        padding: '0.8rem 1.5rem',
    })

    const [formData, setformData] = useState({Name:'',Email:'',PhoneNumber:'', Website:'', Description:''})

    const form = useRef();

    const handleSubmits = (e) => {
        e.preventDefault();
        console.log('x');
        emailjs.sendForm('service_p1co2fb', 'template_fqmixhf', form.current, '-yh_aCVLqtSwBXbKc')
        setformData({Name:'',Email:'',PhoneNumber:'', Website:'', Description:''})
    }

    const handleChanges = (e) => {
        const name = e.target.name
        const value = e.target.value
        setformData({...formData, [name]: value})
    }

  return (
    <>
        <ParticlesW />
        <Container>
            <Grid mt={10} pt={10} container xs={12} justifyContent='center' alignItems='center' gap='5rem' sx={{flexDirection: {lg: 'row', xs: 'column'}}}>
                <Grid container xs={12} lg={6} justifyContent='start' alignItems='flex-start'>
                    <Grid item><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1.5rem', md: '3rem'}}} variant='h2' fontWeight={600} color='white'>How building <br /><span style={{color:'#1AABE3'}}>development team</span> works</Typography></Grid>
                    <br />
                    <Grid item><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'}}} variant='h6' color='white'>Extend your tech team with top talent. We oversee the execution and management of your software projects with our proven processes and tech mentorship</Typography></Grid>
                </Grid>
                <Grid container xs={12} lg={5} justifyContent='center' alignItems='center'>
                    <img src={pic1} style={{width: '100%', borderRadius: '10px'}} alt="" />
                </Grid>
            </Grid>
        </Container>

        <Container style={{marginTop: '5rem'}}>
            <Grid container xs={12} justifyContent='center' alignItems='center' gap='5rem' sx={{flexDirection: {xs: 'column-reverse', lg: 'row'}}}>

                <Grid container justifyContent='center' alignItems='center' xs={12} lg={5}>
                     <img src={pic7} style={{width: '100%', borderRadius: '5px'}} alt="" />
                </Grid>

                <Grid container xs={12} lg={6} justifyContent='start' alignItems='flex-start'>
                    <Grid item><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1.5rem', md: '3rem'}}} color='white' variant='h4' fontWeight='600'>Get guidance on the <span style={{color: '#1AABE3'}}>right technologies</span> and skills to invest in</Typography></Grid>
                    <br />
                    <Grid item><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'}}} color='white' variant='h5'>Let us know about your project needs and goals—the more detailed the better. Depending on your goals, we prepare a tech roadmap with a hiring plan and technical consultation.</Typography></Grid>
                </Grid>

            </Grid>
        </Container>

        <Grid container justifyContent='center' alignItems='center' mt='5rem' xs={12}>
            <Grid item xs={12} lg={12} justifyContent='center'><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1.5rem', md: '3.5rem'}}} color='white' textAlign='center' variant='h3' fontWeight='600'>Hire the <span style={{color: '#1AABE3'}}>best developers </span>for your project</Typography></Grid>
            <Grid item xs={11} lg={6}><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'}, mt: '1rem'}} color='white' variant='h6'>Whether you want to add a member to your existing tech team or build a standalone project from scratch, we can help you find the best developers for the job and make sure the project is successfully delivered on time.</Typography></Grid>
        </Grid>

        <Container style={{marginTop: '5rem'}}>
            <Grid container xs={12} sx={{ justifyContent: {xs: 'center', lg: 'space-between'} }} gap='1rem'>
                <Grid container xs={12} lg={6} justifyContent='center' >
                    <Grid item xs={12} justifyContent='center'>
                        <Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1.5rem', md: '3rem'}}} color='white' variant='h4' fontWeight='600'>You won’t know where <span style={{color: '#1AABE3'}}>your team </span>ends and ours begins</Typography>
                        <br />
                            <Typography color='white' sx={{ mt: 0, mb: 2, backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'} }} variant="h6" component="div">
                            We match our development operations to your engineering culture, processes and ways of working. Our developers work within your systems and our tech leads receive their priorities directly from you.
                            </Typography>  
                    </Grid>
                </Grid>
                <Grid container xs={12} lg={5.5} justifyContent='center' >
                    <img src={pic1} style={{width: '100%', borderRadius: '5px'}} alt="" />
                </Grid>
            </Grid>
        </Container>

        <Container style={{marginTop: '5rem'}}>
            <Grid container xs={12}  justifyContent='space-between' alignItems='center' sx={{ justifyContent: {xs: 'center', lg: 'space-between'},flexDirection: {xs: 'column-reverse', lg: 'row'} }} gap='1rem'>

                <Grid container xs={12} lg={5.5} justifyContent='center'>
                  <img src={pic2} style={{width: '100%', borderRadius: '5px'}} alt="" />
                </Grid>

                <Grid container xs={12} lg={6} justifyContent='center' >
                    <Grid item xs={12} justifyContent='center'>
                        <Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1.5rem', md: '3rem'}}} color='white' variant='h4' fontWeight='600'>Get <span style={{color: '#1AABE3'}}>quality software delivered, </span>on time, on budget</Typography>
                        <br />
                        <Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)'}} color='white' variant='body1'>With our fully-managed software development service, you get proven processes and expertise that ensures remote engagement delivers successful results. We take ownership and guarantee the delivery and quality of your project.</Typography>
                        <br />
                        <Link style={{textDecoration: 'none', backgroundColor: 'rgba(29, 29, 29, 1)'}} to="/Contact-Us"><SubmitBtn>Book Meeting</SubmitBtn></Link>
                    </Grid>
                </Grid>
                
            </Grid>
        </Container>

        <Container style={{marginTop: '5rem'}}>
            <Grid container xs={12} gap='5rem' sx={{ justifyContent: {xs: 'center', lg: 'space-between'}, alignItems: 'center', flexDirection: { xs: 'column', lg: 'row' } }}>
                <Grid container flex={1} gap='5rem'>
                    <form style={{width: '100%', display: 'flex', gap: '2rem', flexDirection: 'column'}} ref={form} onSubmit={handleSubmits}>
                        <Grid container xs={12} justifyContent='space-between' gap='1rem'>
                            <Grid item  flex={1}><Input required sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} type='text' name='Name' value={formData.Name} onChange={handleChanges} placeholder="Name" fullWidth/></Grid>
                            <Grid item  flex={1}><Input required sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} type='email' name='Email' value={formData.Email} onChange={handleChanges} placeholder="Email" fullWidth/></Grid>
                        </Grid>
                        <Grid container xs={12} justifyContent='space-between' gap='1rem'>
                            <Grid item flex={1}><Input sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} type='text' name='PhoneNumber' value={formData.PhoneNumber} onChange={handleChanges} placeholder="Phone Number (optional)" fullWidth/></Grid>
                            <Grid item flex={1}><Input sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} type='text' name='Website' value={formData.Website} onChange={handleChanges} placeholder="Website Domain" fullWidth/></Grid>
                        </Grid>
                        <Grid container xs={12} justifyContent='space-between' gap='1rem'>
                            <Grid item flex={1}><Input required sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} type='text' name='Description' value={formData.Description} onChange={handleChanges} placeholder="Description Of Project" fullWidth/></Grid>
                        </Grid>
                        <Grid container xs={12} justifyContent='space-between' gap='1rem'>
                            {/* <input style={{background:'#26244C', padding: '1rem 3rem', border: 'none', borderRadius: '5px', color: 'white', cursor: 'pointer'}} type='submit'/> */}
                            <button style={{backgroundColor: 'rgba(29, 29, 29, 1)'}} type='submit' className='contact-button' onClick={()=> setsuccess(true)}>Submit</button>
                        </Grid>
                        <div>
                            {
                                success 
                                ? 
                                    <Stack sx={{ width: '70%' }} spacing={2}>
                                        <Alert sx={{backgroundColor: '#333', color: 'white', fontSize: '1.1rem'}} severity="success">Your Message was successfully sent!</Alert>
                                    </Stack>
                                : 
                                    <></>
                            }
                        </div>
                    </form>
                </Grid>
                <Grid container flex={1} gap='2rem'>
                    <Grid item xs={12} sx={{ mb: 5}}><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1.5rem', md: '3rem'}}} color='white' variant='h4' fontWeight='600'>Should we <span style={{color: '#1AABE3'}}>work together?</span></Typography></Grid>
                    <Grid item xs={12} sx={{ mb: 5}}>
                        <Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'}}} color='white' variant='body1'>Do you want us to develop and design an exclusive web solution for your company?</Typography>
                        <br />
                        <Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'}}} color='white' variant='body1'>So don't hesitate to contact Excyan today to find out more about how we can make your dream of a beautiful and compelling web and mobile solution a reality.</Typography>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid container flex={1} flexDirection='column'>
                            <Grid item color='white' sx={{backgroundColor: 'rgba(29, 29, 29, 1)'}}>Email</Grid>
                            <Grid item color='white' sx={{backgroundColor: 'rgba(29, 29, 29, 1)'}}>Contact@excyan.com</Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </Grid>
        </Container>
    </>
  )
}

export default HowItWorks