import React, { useState } from 'react'
import { Box, Stack } from '@mui/system'
import { Outlet } from 'react-router-dom'
import NewNavbar from '../Navbar/NewNavbar'
import Footer from '../Footer/Footer'
import Navmobile from '../Navbar/Navmobile'

const SharedLayout = ({theme}) => {
  const [navOn, setnavOn] = useState(false)
  const handleClick = () => {setnavOn(!navOn)}
  return (
    <Box>
        <Stack>
            {
              navOn 
              ?
                <Navmobile setnavOn={setnavOn} handleClick={handleClick} />
              :
                <>
                  <NewNavbar navOn={navOn} handleClick={handleClick} theme={theme} />
                  <Outlet theme={theme} />
                  <Footer theme={theme} />
                </>
            }
        </Stack>
    </Box>
  )
}

export default SharedLayout