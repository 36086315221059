import { Alert, Box, Container, Divider, Grid, Input, List, ListItem, ListItemIcon, Paper, Stack, styled, Typography } from '@mui/material'
import React, { useState, useRef } from 'react'
import pic1 from '../../Assets/home1.jpg'
import pic2 from '../../Assets/pic15.jpg'
import pic3 from '../../Assets/pic14.jpg'
import pic4 from '../../Assets/pic13.jpg'
import pic5 from '../../Assets/pic12.jpg'
import pic6 from '../../Assets/pic11.jpg'
import pic7 from '../../Assets/pic10.jpg'
import pic8 from '../../Assets/pic1.jpg'

import PropTypes from 'prop-types';
import emailjs from '@emailjs/browser';
import ParticlesW from './ParticlesW'

import CheckIcon from '@mui/icons-material/Check';

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: 'green',
  color: 'white',
  padding: '1rem',
  margin: '1rem 0',
  borderRadius: '10px'
}));

const CardItem = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  padding: "1rem"
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: {xs: 0, lg: '4rem 10rem' }}}>
            <Typography variant='h6' color='white'>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

const CloudDev = () => {
  const [success, setsuccess] = useState(false)

    if(success) {
        setTimeout(() => {
            setsuccess(false)
        }, 3000);
    }

    const [formData, setformData] = useState({Name:'',Email:'',PhoneNumber:'', Website:'', Description:''})

    const form = useRef();

    const handleSubmits = (e) => {
        e.preventDefault();
        console.log('x');
        emailjs.sendForm('service_p1co2fb', 'template_fqmixhf', form.current, '-yh_aCVLqtSwBXbKc')
        setformData({Name:'',Email:'',PhoneNumber:'', Website:'', Description:''})
    }

    const handleChanges = (e) => {
        const name = e.target.name
        const value = e.target.value
        setformData({...formData, [name]: value})
    }

  return (
    <>
        <ParticlesW />
        <Container>
            <Grid mt={10} pt={10} container xs={12} justifyContent='center' alignItems='center' gap='5rem' sx={{flexDirection: {lg: 'row', xs: 'column'}}}>
                <Grid container xs={12} lg={6} justifyContent='start' alignItems='flex-start'>
                    <Grid item><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1.5rem', md: '3rem'}}} variant='h3' fontWeight={600} color='white'>Cloud native engineering to build scalable, reliable and operationally <br /><span style={{color:'#1AABE3'}}>excellent digital products </span></Typography></Grid>
                    <br />
                    <Grid item><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'}}} variant='h6' color='white'>Legacy tech can’t keep up with modern business requirements anymore. We help you leverage cloud-native technologies to build auto-scaling infrastructures, curate delightful customer experiences, and architect cutting-edge digital solutions.</Typography></Grid>
                </Grid>
                <Grid container xs={12} lg={5} justifyContent='center' alignItems='center'>
                    <img src={pic1} style={{width: '100%', borderRadius: '10px'}} alt="" />
                </Grid>
            </Grid>
        </Container>

        <Container style={{marginTop: '5rem'}}>
            <Grid container xs={12} justifyContent='center' alignItems='center' gap='5rem' sx={{flexDirection: {xs: 'column-reverse', lg: 'row'}}}>

                <Grid container justifyContent='center' alignItems='center' xs={12} lg={5}>
                     <img src={pic2} style={{width: '100%', borderRadius: '5px'}} alt="" />
                </Grid>

                <Grid container xs={12} lg={6} justifyContent='start' alignItems='flex-start'>
                    <Grid item><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)',fontSize: {xs: '1.5rem', md: '3rem'}}} color='white' variant='h4' fontWeight='600'>Become intrinsically agile and improve time to market with <span style={{color: '#1AABE3'}}>cloud-native solutions</span></Typography></Grid>
                    <br />
                    <Grid item><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'}}} color='white' variant='body1'>Modern digital experiences require smarter methodologies, better technologies, and innovative approaches to win users’ trust and attention. Cloud-native technologies have the potential to deliver sophisticated software solutions with such experiences.</Typography></Grid>
                    <br />
                    <Grid item><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'}}} color='white' variant='body1'>However, you need a reliable partner who can oversee the perfect implementation of cloud-native tech to deliver those delightful digital products to your customers. With 12+ years of experience, 800+ cloud experts in the ranks, and Fortune500 companies as satisfied clients, Excyan equips you with the right expertise to make the most of cloud-native technologies.</Typography></Grid>
                    <br />
                    <Grid item><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'}}} color='white' variant='body1'>Excyan gives you access to seasoned cloud architects, skilled DevOps experts, cross-functional agile teams, and proficient data engineering experts. Whether it be modernizing an aging software, building a solution from scratch, or modifying infrastructure to keep up with business growth, Excyan utilizes the best cloud practices and methodologies to fulfill your business goals.</Typography></Grid>
                </Grid>

            </Grid>
        </Container>

        <Grid container justifyContent='center' alignItems='center' mt='5rem' xs={12}>
            <Grid item xs={12} lg={12} justifyContent='center'><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1.5rem', md: '3rem'}}} color='white' textAlign='center' variant='h3' fontWeight='600'>Our cloud-native <span style={{color: '#1AABE3'}}>services</span></Typography></Grid>
            <Grid item xs={11} lg={6}><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'}, mt: '1rem'}} color='white' variant='h6'>Choose cloud-native capabilities to solve complex business challenges, support your growing business, and deliver customer-centric solutions. Excyan offers a range of cloud-native services to help navigate your way to business objectives.</Typography></Grid>
        </Grid>

        <Container style={{marginTop: '5rem'}}>
          <Grid container xs={12} sx={{ justifyContent: {xs: 'center', lg: 'space-between'} }} gap='1rem'>
            <Grid container xs={12} lg={6} justifyContent='center'>
              <CardItem>
                  <Grid container xs={12} flexDirection='column' justifyContent='space-between' alignItems='start'>
                      <Grid item><Typography color= 'black' variant='h6' fontWeight={600}>Cloud development services</Typography></Grid>
                      <Grid item><Typography color= 'black' textAlign='start' variant='body2'>Maximize your business value by adopting end-to-end cloud solutions. With scalable computing resources at focus, we help you migrate resource-intensive processes, bringing better business agility and achieving the flexibility your business needs.</Typography></Grid>
                      <Grid item><Divider /></Grid>
                      <Grid item>
                          <Demo sx={{padding: 0}}>
                              <List >
                                  <Box >
                                      <ListItem>
                                          <ListItemIcon>
                                              <CheckIcon style={{color: 'white'}}/>
                                      </ListItemIcon>
                                      <Typography variant='body2'>AWS Cloud Services</Typography>
                                      </ListItem>
                                  </Box>
                                  <Box>
                                      <ListItem>
                                          <ListItemIcon>
                                              <CheckIcon style={{color: 'white'}}/>
                                      </ListItemIcon>
                                      <Typography variant='body2'>Google Cloud Services</Typography>
                                      </ListItem>
                                  </Box>
                                  <Box>
                                      <ListItem>
                                          <ListItemIcon>
                                              <CheckIcon style={{color: 'white'}}/>
                                      </ListItemIcon>
                                      <Typography variant='body2'>Azure Cloud Services</Typography>
                                      </ListItem>
                                  </Box>
                                  <Box>
                                      <ListItem>
                                          <ListItemIcon>
                                              <CheckIcon style={{color: 'white'}}/>
                                      </ListItemIcon>
                                      <Typography variant='body2'>From handling of cloud Computing, load balancing to entire infrastructure and many more.</Typography>
                                      </ListItem>
                                  </Box>
                              </List>
                          </Demo>
                      </Grid>
                  </Grid>
              </CardItem>
            </Grid>
            <Grid container xs={12} lg={5.5} justifyContent='center' >
              <img src={pic3} style={{width: '100%', borderRadius: '5px'}} alt="" />
            </Grid>
          </Grid>
        </Container>

        <Container style={{marginTop: '5rem'}}>
          <Grid container xs={12}  justifyContent='space-between' alignItems='center' sx={{ justifyContent: {xs: 'center', lg: 'space-between'},flexDirection: {xs: 'column-reverse', lg: 'row'} }} gap='1rem'>
              <Grid container xs={12} lg={5.5} justifyContent='center' >
                  <Grid item xs={12} justifyContent='center'>
                      <Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1.5rem', md: '1.25rem'}}} color='white' variant='h4' fontWeight='600'>DevOps <span style={{color: '#1AABE3'}}>services </span></Typography>
                      <br />
                      <Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'}}} color='white' variant='h6'>Sync your development and operational capabilities to accelerate product lifecycle with our DevOps managed services. With continuous integration and delivery at the focus, we help you automate processes, strengthen app security, upgrade architecture, and more.</Typography>
                  </Grid>
              </Grid>
              <Grid container xs={12} lg={6} justifyContent='center'>
                <CardItem>
                  <Grid container xs={12} flexDirection='column' justifyContent='space-between' alignItems='start'>
                      <Grid item><Typography color= 'black' variant='h6' fontWeight={600}>Cloud development services</Typography></Grid>
                      <Grid item><Typography color= 'black' textAlign='start' variant='body2'>Maximize your business value by adopting end-to-end cloud solutions. With scalable computing resources at focus, we help you migrate resource-intensive processes, bringing better business agility and achieving the flexibility your business needs.</Typography></Grid>
                      <Grid item><Divider /></Grid>
                      <Grid item>
                          <Demo sx={{padding: 0}}>
                              <List>
                                  <Box>
                                      <ListItem>
                                          <ListItemIcon>
                                              <CheckIcon style={{color: 'white'}}/>
                                      </ListItemIcon>
                                      <Typography variant='body2'>CI / CD Pipelines</Typography>
                                      </ListItem>
                                  </Box>
                                  <Box>
                                      <ListItem>
                                          <ListItemIcon>
                                              <CheckIcon style={{color: 'white'}}/>
                                      </ListItemIcon>
                                      <Typography variant='body2'>Automated Pipelines</Typography>
                                      </ListItem>
                                  </Box>
                                  <Box>
                                      <ListItem>
                                          <ListItemIcon>
                                              <CheckIcon style={{color: 'white'}}/>
                                      </ListItemIcon>
                                      <Typography variant='body2'>DevOps consulting</Typography>
                                      </ListItem>
                                  </Box>
                                  <Box>
                                      <ListItem>
                                          <ListItemIcon>
                                              <CheckIcon style={{color: 'white'}}/>
                                      </ListItemIcon>
                                      <Typography variant='body2'>Cloud infrastructure management and many more.</Typography>
                                      </ListItem>
                                  </Box>
                              </List>
                          </Demo>
                      </Grid>
                  </Grid>
                </CardItem>
              </Grid>
          </Grid>
        </Container>

        <Grid container justifyContent='center' alignItems='center' mt='5rem' xs={12}>
            <Grid item xs={12} lg={12} justifyContent='center'><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1.5rem', md: '3rem'}}} color='white' textAlign='center' variant='h3' fontWeight='600'>Benefits of Working With <br /> <span style={{color: '#1AABE3'}}>Excyan</span></Typography></Grid>
            <Grid item xs={11} lg={6}><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'}, mt: '1rem'}} color='white' variant='h6'>There is no one-size, one-idea-fits-all kind of solution. At Excyan, our software engineers are versatile and constantly improve on their skillset who can adapt and evolve according to the requirement of your application development.</Typography></Grid>
        </Grid>

        <Container style={{marginTop: '5rem'}}>
            <Grid container xs={12} sx={{ justifyContent: {xs: 'center', lg: 'space-between'} }} gap='1rem'>
                <Grid container xs={12} lg={6} justifyContent='center' >
                    <Grid item xs={12} justifyContent='center'>
                        <Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1.5rem', md: '2rem'}}} color='white' variant='h4' fontWeight='600'>Eminent <span style={{color: '#1AABE3'}}>expertise </span></Typography>
                        <br />
                            <Typography color='white' sx={{ mt: 0, mb: 2, backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'} }} variant="h6" component="div">
                            Our expert development team dedicates 100% of their time to the assigned client. They also nurture long-term relationships and gain in-depth knowledge of their business domain.
                            </Typography>  
                    </Grid>
                </Grid>
                <Grid container xs={12} lg={5.5} justifyContent='center' >
                    <img src={pic4} style={{width: '100%', borderRadius: '5px'}} alt="" />
                </Grid>
            </Grid>
        </Container>

        <Container style={{marginTop: '5rem'}}>
            <Grid container xs={12} sx={{ justifyContent: {xs: 'center', lg: 'space-between'} }} gap='1rem'>
                <Grid container xs={12} lg={5.5} justifyContent='center' >
                    <img src={pic5} style={{width: '100%', borderRadius: '5px'}} alt="" />
                </Grid>
                <Grid container xs={12} lg={6} justifyContent='center' >
                    <Grid item xs={12} justifyContent='center'>
                        <Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1.5rem', md: '2rem'}}} color='white' variant='h4' fontWeight='600'>Intrinsically <span style={{color: '#1AABE3'}}>agile </span></Typography>
                        <br />
                            <Typography color='white' sx={{ mt: 0, mb: 2, backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'} }} variant="h6" component="div">
                            Our strong pillar is that we follow adaptive planning in an agile approach to build apps flexibly. This helps us reduce repetition in work and empower cross-functional team collaboration to deliver projects on time.
                            </Typography>  
                    </Grid>
                </Grid>
            </Grid>
        </Container>

        <Container style={{marginTop: '5rem'}}>
            <Grid container xs={12} sx={{ justifyContent: {xs: 'center', lg: 'space-between'} }} gap='1rem'>
                <Grid container xs={12} lg={6} justifyContent='center' >
                    <Grid item xs={12} justifyContent='center'>
                        <Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1.5rem', md: '2rem'}}} color='white' variant='h4' fontWeight='600'>End-to-end <span style={{color: '#1AABE3'}}>services </span></Typography>
                        <br />
                            <Typography color='white' sx={{ mt: 0, mb: 2, backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'} }} variant="h6" component="div">
                            We have a proven record of satisfied clients. Be its cloud solutions, development or mobile, web or desktop apps, creation of custom, enterprise, or SaaS apps. We have a team ready for your every need.
                            </Typography>  
                    </Grid>
                </Grid>
                <Grid container xs={12} lg={5.5} justifyContent='center' >
                    <img src={pic6} style={{width: '100%', borderRadius: '5px'}} alt="" />
                </Grid>
            </Grid>
        </Container>

        <Container style={{marginTop: '5rem'}}>
            <Grid container xs={12} sx={{ justifyContent: {xs: 'center', lg: 'space-between'} }} gap='1rem'>
                <Grid container xs={12} lg={5.5} justifyContent='center' >
                    <img src={pic7} style={{width: '100%', borderRadius: '5px'}} alt="" />
                </Grid>
                <Grid container xs={12} lg={6} justifyContent='center' >
                    <Grid item xs={12} justifyContent='center'>
                        <Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1.5rem', md: '2rem'}}} color='white' variant='h4' fontWeight='600'>Scalable <span style={{color: '#1AABE3'}}>team </span></Typography>
                        <br />
                            <Typography color='white' sx={{ mt: 0, mb: 2, backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'} }} variant="h6" component="div">
                            Build a distributed team filled with the right talent that can meet different market demands. Incorporate additional developers who can expand your existing team and add value to your project.
                            </Typography>  
                    </Grid>
                </Grid>
            </Grid>
        </Container>

        <Container style={{marginTop: '5rem'}}>
            <Grid container xs={12} sx={{ justifyContent: {xs: 'center', lg: 'space-between'} }} gap='1rem'>
                <Grid container xs={12} lg={6} justifyContent='center' >
                    <Grid item xs={12} justifyContent='center'>
                        <Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1.5rem', md: '2rem'}}} color='white' variant='h4' fontWeight='600'>Unmatched <span style={{color: '#1AABE3'}}>quality </span></Typography>
                        <br />
                            <Typography color='white' sx={{ mt: 0, mb: 2, backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'} }} variant="h6" component="div">
                            Beyond development, we take it seriously to make sure the deployed product is compliant with set standards for security, performance, dependencies, and so much more. We make room for constant improvement.
                            </Typography>  
                    </Grid>
                </Grid>
                <Grid container xs={12} lg={5.5} justifyContent='center' >
                    <img src={pic8} style={{width: '100%', borderRadius: '5px'}} alt="" />
                </Grid>
            </Grid>
        </Container>

        <Container style={{marginTop: '5rem'}}>
            <Grid container xs={12} gap='5rem' sx={{ justifyContent: {xs: 'center', lg: 'space-between'}, alignItems: 'center', flexDirection: { xs: 'column', lg: 'row' } }}>
                <Grid container flex={1} gap='5rem'>
                    <form style={{width: '100%', display: 'flex', gap: '1rem', flexDirection: 'column'}} ref={form} onSubmit={handleSubmits}>
                        <Grid container xs={12} justifyContent='space-start' gap={'1rem'}>
                            <Grid item xs={12} sm={5.5}><Input required sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} type='text' name='Name' value={formData.Name} onChange={handleChanges} placeholder="Name" fullWidth/></Grid>
                            <Grid item xs={12} sm={5.5}><Input required sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} type='email' name='Email' value={formData.Email} onChange={handleChanges} placeholder="Email" fullWidth/></Grid>
                        </Grid>
                        <Grid container xs={12} justifyContent='space-start' gap='1rem'>
                            <Grid item xs={12} sm={5.5}><Input sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} type='text' name='PhoneNumber' value={formData.PhoneNumber} onChange={handleChanges} placeholder="Phone Number (optional)" fullWidth/></Grid>
                            <Grid item xs={12} sm={5.5}><Input sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} type='text' name='Website' value={formData.Website} onChange={handleChanges} placeholder="Website Domain" fullWidth/></Grid>
                        </Grid>
                        <Grid container xs={12} justifyContent='space-start' gap='1rem'>
                            <Grid item xs={12} sm={11.35}><Input required sx={{color: 'white', border: '1px solid white', padding: '0.5rem', backgroundColor: 'rgba(29, 29, 29, 1)'}} type='text' name='Description' value={formData.Description} onChange={handleChanges} placeholder="Description Of Project" fullWidth/></Grid>
                        </Grid>
                        <Grid container xs={12} justifyContent='space-start' gap='1rem'>
                            {/* <input style={{background:'#26244C', padding: '1rem 3rem', border: 'none', borderRadius: '5px', color: 'white', cursor: 'pointer'}} type="submit" /> */}
                            <button  className='contact-button' type='submit' onClick={()=> setsuccess(true)}>Submit</button>
                        </Grid>
                        <div>
                            {
                                success 
                                ? 
                                    <Stack sx={{ width: '70%' }} spacing={2}>
                                        <Alert sx={{backgroundColor: '#333', color: 'white', fontSize: '1.1rem'}} severity="success">Your Message was successfully sent!</Alert>
                                    </Stack>
                                : 
                                    <></>
                            }
                        </div>
                    </form>
                </Grid>
                <Grid container flex={1} gap='2rem'>
                    <Grid item xs={12} sx={{ mb: 5}}><Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1.5rem', md: '2rem'}}} color='white' variant='h4' fontWeight='600'>Should we <span style={{color: '#1AABE3'}}>work together?</span></Typography></Grid>
                    <Grid item xs={12} sx={{ mb: 5}}>
                        <Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'}}} color='white' variant='body1'>Do you want us to develop and design an exclusive web solution for your company?</Typography>
                        <br />
                        <Typography sx={{backgroundColor: 'rgba(29, 29, 29, 1)', fontSize: {xs: '1rem', md: '1.25rem'}}} color='white' variant='body1'>So don't hesitate to contact Excyan today to find out more about how we can make your dream of a beautiful and compelling web and mobile solution a reality.</Typography>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid container flex={1} flexDirection='column'>
                            <Grid item color='white' sx={{backgroundColor: 'rgba(29, 29, 29, 1)'}}>Email</Grid>
                            <Grid item color='white' sx={{backgroundColor: 'rgba(29, 29, 29, 1)'}}>Contact@excyan.com</Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </Grid>
        </Container>
    </>
  )
}

export default CloudDev